import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
  notify,
  getProperties,
} from "../../Utils/ReusableFunctions";
import { useShowInfo } from "../../Context/ShowInfo";
import { MDBInput } from "mdb-react-ui-kit";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { updateUser } from "../../DataService/UsersDataService";
import { CreateUser } from "./CreateUser";
import { AssignUserToRoleModal } from "./AssignUserToRoleModal";

export const Users = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setCustomMessage, languageOptions } = useShowInfo();
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [properties, setProperties] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.userPropertiesApi,
      sessionToken,
      languageOptions,
      setProperties
    );
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.userApi, sessionToken).then((data) => {
      setUserList(data.value);
      handleGetProperties();
    });
    fetchData(ApiUrls.roleApi, sessionToken).then((data) => {
      setRoleList(data.value);
    });
  }, [sessionToken, handleGetProperties]);

  const filteredList = filterListByName(userList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedUser, setSelectedUser);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedUser]);

  const handleSave = async () => {
    try {
      const updatedUserList = await updateUser(
        ApiUrls.userApi,
        sessionToken,
        selectedUser,
        fetchData
      );
      console.log("User updated successfully");
      setCustomMessage("User updated successfully");
      setUserList(updatedUserList);
    } catch (error) {
      notify("Error updating user");
      console.error("Error updating user:", error);
    }
  };

  const handleInputChange = (fieldName, value) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  const getRoleNames = (roleIds) => {
    const roleNames = roleIds
      .map((roleId) => {
        const role = roleList.find((role) => role.ID === roleId);
        return role ? role.Name : null;
      })
      .filter((roleName) => roleName !== null);

    return roleNames.length > 0 ? roleNames.join(", ") : "No Role";
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="d-flex align-items-center">
              Users
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#userModal"
              >
                <i
                  className="bi bi-plus-circle-dotted"
                  style={{ fontSize: "1rem" }}
                ></i>
              </button>
            </h5>
            <div>
              <input
                id="userSearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedUser?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedUser(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedUser && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>User Details</h5>
              </div>
              <MDBInput
                name="usersName"
                id="usersName"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedUser.Name}
                onChange={(e) => handleInputChange("Name", e.target.value)}
                label={properties[5]?.Name}
                type="Name"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[5]?.Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBInput
                name="userEmail"
                id="userEmail"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedUser.Username}
                onChange={(e) => handleInputChange("Username", e.target.value)}
                label={properties[0]?.Name}
                type="email"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[0]?.Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <div
                className="form-check form-switch text-start mb-2"
                style={{
                  border: "solid",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  borderColor: "#BDBDBD",
                }}
              >
                <div className="m-1" style={{ height: "24px" }}>
                  <input
                    name="active"
                    checked={selectedUser.Active}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="active"
                    onChange={(e) =>
                      handleInputChange("Active", e.target.checked)
                    }
                    onFocus={() =>
                      handleInputSelect(true, properties[1]?.Description)
                    }
                    onBlur={() => handleInputSelect(false)}
                  />
                  <label className="form-check-label" htmlFor="active">
                    {properties[1]?.Name}
                  </label>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="accordion" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Roles
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          {getRoleNames(
                            selectedUser.Roles.map((role) => role.RoleID)
                          )}
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-sm btn-primary w-100 me-5 ms-5 mb-3"
                            data-bs-toggle="modal"
                            data-bs-target="#userToRole"
                          >
                            ASSIGN NEW ROLE TO THIS USER
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateUser setUserList={setUserList} />
      <AssignUserToRoleModal
        selectedUser={selectedUser}
        setUserList={setUserList}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
};
