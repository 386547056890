import { Routes, Route } from "react-router-dom";
import { Users } from "../Users/Users";
import { Roles } from "../Roles/Roles";
import { InfoBarBottom } from "../Navigation/InfoBarBottom";
import { ActiveShowInfo } from "../../Context/ShowInfo";
import { Currency } from "../Currency/Currency";
import { Languages } from "../Language/Languages";
import { Customers } from "../Customers/Customers";
import { Cart } from "../Cart/Cart";
import { PriceGroup } from "../PriceGroup/PriceGroup";
import { ProductsList } from "../Products/ProductsList";
import { CreateProduct } from "../Products/CreateProduct";
import { Properties } from "../Properties/Properties";

export const RouteComponent = () => {
  return (
    <ActiveShowInfo>
      <div className="container mt-3">
        <Routes>
          <Route path="/users" element={<Users />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/currencies" element={<Currency />} />
          <Route path="/languages" element={<Languages />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/pricegroups" element={<PriceGroup />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/createproduct" element={<CreateProduct />} />
          <Route path="/properties" element={<Properties />} />
        </Routes>
        <InfoBarBottom />
      </div>
    </ActiveShowInfo>
  );
};
