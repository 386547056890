import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useShowInfo } from "../../Context/ShowInfo";
import { assignUserToRole } from "../../DataService/UsersDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";

export const AssignUserToRoleModal = ({
  selectedUser,
  setUserList,
  setSelectedUser,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [roleList, setRoleList] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const { setCustomMessage } = useShowInfo();

  useEffect(() => {
    fetchData(ApiUrls.roleApi, sessionToken).then((data) => {
      setRoleList(data.value);
    });
  }, [sessionToken]);

  const handleUserChange = (event) => {
    setSelectedRoleId(event.target.value);
  };

  const handleAssignRole = async () => {
    try {
      const updatedUserList = await assignUserToRole(
        ApiUrls.userRoleApi,
        sessionToken,
        selectedUser?.ID,
        selectedRoleId,
        setCustomMessage
      );
      setUserList(updatedUserList);
      const updatedUser = updatedUserList.find(
        (user) => user.ID === selectedUser.ID
      );
      if (updatedUser) {
        setSelectedUser(updatedUser);
      }
    } catch (error) {
      console.error("Error assigning role to user:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="userToRole"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="userToRoleLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="userToRoleLabel">
              Assign role to user
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-2" style={{ textAlign: "left" }}>
              {selectedUser?.Name}
            </div>
            <select
              id="selectRole"
              className="form-select"
              aria-label="Default select example"
              onChange={handleUserChange}
            >
              <option>Choose role</option>
              {roleList.map((role) => (
                <option key={role.ID} value={role.ID}>
                  {role.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="closeButton"
              type="button"
              className="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              onClick={handleAssignRole}
            >
              Assign Role
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
