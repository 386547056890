import { fetchData } from "../Utils/ReusableFunctions";

export const updateLanguage = async (
  apiUrl,
  sessionToken,
  selectedLanguage,
  fetchData
) => {
  if (!selectedLanguage) {
    throw new Error("No language selected");
  }

  const patchUrl = `${apiUrl}(${selectedLanguage.ID})`;
  const patchData = {
    Code: selectedLanguage.Code,
    Name: selectedLanguage.Name,
    ID: selectedLanguage.ID,
  };

  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });

  if (!response.ok) {
    throw new Error(`Error updating language. Status: ${response.status}`);
  }

  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};

export const createLanguage = async (
  apiUrl,
  sessionToken,
  language,
  notify,
  setCustomMessage,
  setLanguage,
  setLanguageList
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(language),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Language created:", data);
      setCustomMessage("Language created");
      setLanguage({
        Code: "",
        Name: "",
      });
      fetchData(apiUrl, sessionToken).then((data) => {
        setLanguageList(data.value);
      });
    } else {
      notify("Error creating language");
      throw Error("Failed to create language");
    }
  } catch (error) {
    notify("Error creating language");
    console.error("Error creating language:", error);
  }
};
