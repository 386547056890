import { fetchData } from "../Utils/ReusableFunctions";

export const createProperty = async (
  apiUrl,
  sessionToken,
  property,
  notify,
  setCustomMessage,
  setProperty,
  setProperties
) => {
  try {
    console.log(property);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(property),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("property created:", data);
      setCustomMessage("property  created");
      setProperty({
        Code: "",
        Name: "",
      });
      fetchData(apiUrl, sessionToken).then((data) => {
        setProperties(data.value);
      });
    } else {
      notify("Error creating property");
      throw Error("Failed to create property");
    }
  } catch (error) {
    notify("Error creating property ");
    console.error("Error creating property :", error);
  }
};

export const updateProperty = async (
  apiUrl,
  sessionToken,
  selectedRole,
  fetchData
) => {
  if (!selectedRole) {
    throw new Error("No Role selected");
  }
  const patchUrl = `${apiUrl}(${selectedRole.ID})`;
  const patchData = {
    Code: selectedRole.Code,
    Name: selectedRole.Name,
    Description: selectedRole.Description,
  };
  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });

  if (!response.ok) {
    throw new Error(`Error updating role. Status: ${response.status}`);
  }
  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};
