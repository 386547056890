import React, { useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import AsyncSelect from "react-select";
import { createUser } from "../../DataService/UsersDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { MDBInput } from "mdb-react-ui-kit";

export const CreateUser = ({ setUserList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenantList, setTenantList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [user, setUser] = useState({
    Username: "",
    Active: true,
    CustomerID: "",
    Name: "",
  });
  const { setCustomMessage } = useShowInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setUser((prevUser) => ({
      ...prevUser,
      CustomerID: selectedOption.value,
    }));
  };

  const getCustomer = () => {
    fetchData(ApiUrls.customerApi, sessionToken).then((data) => {
      setTenantList(data.value);
    });
  };

  const handleCreateUser = () => {
    createUser(
      ApiUrls.userApi,
      sessionToken,
      user,
      notify,
      setCustomMessage,
      setUser,
      setSelectedValue,
      setUserList
    );
  };

  return (
    <div
      className="modal fade"
      id="userModal"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="userModalLabel">
              Create user
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-2">
              <MDBInput
                id="userUsername"
                type="text"
                className="form-control"
                label="User name"
                name="Username"
                autoComplete="off"
                value={user.Username}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group mb-2">
              <MDBInput
                id="userName"
                type="text"
                className="form-control"
                label="Name"
                name="Name"
                autoComplete="off"
                value={user.Name}
                onChange={handleInputChange}
              />
            </div>
            <AsyncSelect
              className="mb-2"
              id="tenantSelect"
              value={selectedValue}
              onChange={handleChange}
              options={tenantList.map((tenant) => ({
                value: tenant.ID,
                label: tenant.Name,
              }))}
              onMenuOpen={() => {
                getCustomer();
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button "
              className="btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary  btn-sm"
              onClick={handleCreateUser}
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
