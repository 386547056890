import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
  getProperties,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import AsyncSelect from "react-select";
import { AddPropertyValueModal } from "./AddPropertyValueModal";
import { updateProperty } from "../../DataService/PropertiesDataService";
import { CreateProperty } from "./CreateProperty";

export const Properties = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setCustomMessage, languageOptions } = useShowInfo();
  const filteredList = filterListByName(properties, searchString);
  const [propertiesInfo, setPropertiesInfo] = useState([]);
  const [propertiesValue, setPropertiesValue] = useState([]);
  const [selectedPropertyValueID, setSelectedPropertyValueID] = useState(null);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.propertyProperties,
      sessionToken,
      languageOptions,
      setPropertiesInfo
    );
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.properties, sessionToken).then((data) => {
      setProperties(data.value);
      handleGetProperties();
    });

    fetchData(ApiUrls.propertyValues, sessionToken)
      .then((data) => {
        setPropertiesValue(data.value);
      })
      .catch((error) => {
        console.error("Error fetching propertyValue list:", error);
      });
  }, [sessionToken, handleGetProperties]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedProperty,
        setSelectedProperty
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedProperty]);

  const handleSaveProperties = async () => {
    try {
      const updatedPropertyList = await updateProperty(
        ApiUrls.properties,
        sessionToken,
        selectedProperty,
        fetchData
      );
      console.log("Role updated successfully");
      setCustomMessage("Role updated successfully");
      setProperties(updatedPropertyList);
    } catch (error) {
      notify("Error updating role");
      console.error("Error updating role:", error);
    }
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  const removePropertyFilter = async () => {
    if (!selectedPropertyValueID) return;

    try {
      const response = await fetch(
        `${ApiUrls.propertyValues}/${selectedPropertyValueID.ID}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setSelectedPropertyValueID(null);
        setSelectedProperty((prevProp) => ({
          ...prevProp,
          PropertyValueID: null,
        }));
        fetchData(ApiUrls.propertyValues, sessionToken).then((data) => {
          setPropertiesValue(data.value);
        });
        console.log("Property value deleted successfully!", "success");
      } else {
        throw new Error(
          `Failed to delete property value (status ${response.status})`
        );
      }
    } catch (error) {
      console.error("Error deleting property value:", error);
      notify("Failed to delete property value. Please try again.", error);
    }
  };

  const blahrf = (item) => {
    setSelectedProperty(item);
    setSelectedPropertyValueID(null);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5 className="d-flex align-items-center">
              Properties{" "}
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#propertiesModal"
              >
             <i  className="bi bi-plus-circle-dotted" style={{fontSize:"1rem"}}></i>
              </button>
            </h5>
            <div>
              <input
                id="propertiesSearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedProperty?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => blahrf(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedProperty && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Properties Details</h5>
              </div>
              <MDBInput
                name="Code"
                id="Code"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedProperty.Code}
                onChange={(e) => {
                  setSelectedProperty((prevProp) => ({
                    ...prevProp,
                    Name: e.target.value,
                  }));
                }}
                label={propertiesInfo[0].Label}
                type="Name"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, propertiesInfo[3].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBInput
                name="propertiesName"
                id="propertiesName"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedProperty.Name}
                onChange={(e) => {
                  setSelectedProperty((prevProp) => ({
                    ...prevProp,
                    Name: e.target.value,
                  }));
                }}
                label={propertiesInfo[1].Label}
                type="Name"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, propertiesInfo[3].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />

              <div className="row">
                <div className="col-10">
                  {" "}
                  <AsyncSelect
                    placeholder={
                      selectedProperty
                        ? "Property values"
                        : "Please select a property first"
                    }
                    value={
                      selectedPropertyValueID
                        ? propertiesValue.find(
                            (pv) => pv.ID === selectedPropertyValueID
                          )
                        : null
                    }
                    onChange={(selectedOption) =>
                      setSelectedPropertyValueID(selectedOption?.id)
                    }
                    options={propertiesValue
                      .filter(
                        (propertyValue) =>
                          propertyValue.PropertyID === selectedProperty?.ID
                      )
                      .map((propertyValue) => ({
                        id: propertyValue,
                        label: propertyValue.Name,
                      }))}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        textAlign: "left",
                      }),
                      option: (provided) => ({
                        ...provided,
                        textAlign: "left",
                      }),
                    }}
                    onFocus={() =>
                      handleInputSelect(true, propertiesInfo[0]?.Description)
                    }
                    onBlur={() => handleInputSelect(false)}
                  />
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-secondary w-100 btn-danger"
                    onClick={removePropertyFilter}
                  >
                    X
                  </button>{" "}
                </div>
              </div>

              <button
                className="btn w-100 btn-sm mb-2 mt-2 btn-secondary "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                {" "}
                ADD NEW PROPERTY VALUE{" "}
              </button>
              <AddPropertyValueModal
                selectedProperty={selectedProperty}
                setPropertiesValue={setPropertiesValue}
              />
              <div>
                <button
                  onClick={handleSaveProperties}
                  className="mb-3 w-100 btn-sm btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateProperty setProperties={setProperties} />
    </div>
  );
};
