import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { LogInAuth } from "./Components/Authentication/LoginAUth";
import { HandleRouteAndContext } from "./Components/Navigation/HandleRouteAndContext";
import PrivateRoute from "./Components/Navigation/PrivateRoute";


function App() {
  return (

      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<LogInAuth />} />
            <Route element={<PrivateRoute />}>
              <Route path="/shop/*" element={<HandleRouteAndContext />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
   
  );
}

export default App;
