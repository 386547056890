import React, { useState } from "react";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify, fetchData } from "../../Utils/ReusableFunctions";
import { createCustomer } from "../../DataService/CustomerDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import AsyncSelect from "react-select";
import { MDBInput } from "mdb-react-ui-kit";

export const CreateCustomer = ({ setCustomerList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [customer, setCustomer] = useState({
    PriceGroupID: "",
    CustomerDiscountGroupID: 1,
    CurrencyID: "",
    Code: "",
    Name: "",
  });
  const [priceGroupList, setPriceGroupList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedPriceGroup, setSelectedPriceGroup] = useState(null);
  //const [selectedCustomerDiscountGroup, setSelectedCustomerDiscountGroup] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const { setCustomMessage } = useShowInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const handlePriceGroupChange = (selectedOption) => {
    setSelectedPriceGroup(selectedOption);
    setCustomer((prevUser) => ({
      ...prevUser,
      PriceGroupID: selectedOption.value,
    }));
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    setCustomer((prevUser) => ({
      ...prevUser,
      CurrencyID: selectedOption.value,
    }));
  };

  const getPriceGroup = () => {
    fetchData(ApiUrls.priceGroupApi, sessionToken).then((data) => {
      setPriceGroupList(data.value);
    });
  };

  const getCurrency = () => {
    fetchData(ApiUrls.currencyApi, sessionToken).then((data) => {
      setCurrencyList(data.value);
    });
  };

  const handleCreateCustomer = () => {
    createCustomer(
      ApiUrls.customerApi,
      sessionToken,
      customer,
      notify,
      setCustomMessage,
      setCustomer,
      setCustomerList
    );
  };


  return (
    <div
      className="modal fade"
      id="customerModal"
      aria-labelledby="customerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="customerModalLabel">
              Create customer
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-2">
              <MDBInput
                id="nameCustomer"
                type="text"
                className="form-control"
                label="Name"
                name="Name"
                autoComplete="off"
                value={customer.Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group mb-2">
              <MDBInput
                id="codeCUstomer"
                type="text"
                className="form-control"
                label="Code"
                name="Code"
                autoComplete="off"
                value={customer.Code}
                onChange={handleInputChange}
              />
            </div>
            <AsyncSelect
              className="mb-2"
              id="customerSelect"
              value={selectedPriceGroup}
              onChange={handlePriceGroupChange}
              options={priceGroupList.map((priceGroup) => ({
                value: priceGroup.ID,
                label: priceGroup.Name,
              }))}
              onMenuOpen={() => {
                getPriceGroup();
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
              }}
            />
            <AsyncSelect
              className="mb-2"
              id="tenantSelect"
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              options={currencyList.map((currency) => ({
                value: currency.ID,
                label: currency.Name,
              }))}
              onMenuOpen={() => {
                getCurrency();
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button "
              className="btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary  btn-sm"
              onClick={handleCreateCustomer}
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
