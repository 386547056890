import { useEffect, useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { notify } from "../../Utils/ReusableFunctions";

export const ProductsPropertyModal = ({ selectedProduct }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [propertyList, setPropertyList] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [propertyValueList, setPropertyValueList] = useState([]);
  const [selectedPropertyValueId, setSelectedPropertyValueId] = useState(null);

  useEffect(() => {
    fetchData(ApiUrls.properties, sessionToken).then((data) => {
      setPropertyList(data.value);
    });
  }, [sessionToken]);

  useEffect(() => {
    const fetchPropertyValues = async () => {
      if (selectedPropertyId) {
        const data = await fetchData(ApiUrls.propertyValues, sessionToken);
        console.log("All property values:", data.value);
        const filteredValues = data.value.filter(
          (propValue) => propValue.PropertyID === parseInt(selectedPropertyId)
        );
        console.log("Filtered property values:", filteredValues);
        setPropertyValueList(filteredValues);
      }
    };
    fetchPropertyValues();
  }, [selectedPropertyId, sessionToken]);

  const handlePropertyChange = (event) => {
    setSelectedPropertyId(event.target.value);
    setSelectedPropertyValueId(null);
  };

  const handlePropertyValueChange = (event) => {
    setSelectedPropertyValueId(event.target.value);
  };
  
  const assignPropertyToProduct = async () => {

    const isPropertyAlreadyAssigned = propertyValueList.some(
      (item) => item.ID === parseInt(selectedPropertyValueId)
    )
    if (isPropertyAlreadyAssigned) {
      notify("ERROR: This property value is already assigned to the product.");
      return;
    }
    const assignData = {
      ProductID: selectedProduct.ID,
      PropertyID: selectedPropertyId,
      PropertyValueID: selectedPropertyValueId,
    };

    try {
      const response = await fetch(ApiUrls.productPropertyPost, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      });

      if (response.ok) {
        console.log("Property assigned to product successfully");
        notify("Property assigned to product successfully");
      } else {
        console.error(
          "Error assigning property to product. Status:",
          response.status
        );
        notify(
          `Error assigning property to product. Status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error assigning property to product:", error);
      notify("Error assigning property to product: " + error.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="productProperty"
      aria-labelledby="productPropertyLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="productPropertyLabel">
              Assign property to {selectedProduct?.Name}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <select
              id="selectProperty"
              className="form-select mb-2"
              aria-label="Default select example"
              onChange={handlePropertyChange}
            >
              <option>Choose property</option>
              {propertyList.map((prop) => (
                <option key={prop.ID} value={prop.ID}>
                  {prop.Name}
                </option>
              ))}
            </select>

            <select
              id="selectPropertyValue"
              className="form-select"
              aria-label="Default select example"
              onChange={handlePropertyValueChange}
              disabled={selectedPropertyId === null}
            >
              <option>Choose property value</option>
              {propertyValueList.map((prop) => (
                <option key={prop.ID} value={prop.ID}>
                  {prop.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-bs-dismiss="modal"
              onClick={assignPropertyToProduct}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
