import { notify } from "../Utils/ReusableFunctions";
import { fetchData } from "../Utils/ReusableFunctions";

export const updateRole = async (
  apiUrl,
  sessionToken,
  selectedRole,
  fetchData
) => {
  if (!selectedRole) {
    throw new Error("No Role selected");
  }
  const patchUrl = `${apiUrl}(${selectedRole.ID})`;
  const patchData = {
    Code: selectedRole.Code,
    Name: selectedRole.Name,
    Description: selectedRole.Description,
  };
  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });

  if (!response.ok) {
    throw new Error(`Error updating role. Status: ${response.status}`);
  }
  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};

export const createRole = async (
  apiUrl,
  sessionToken,
  role,
  notify,
  setCustomMessage,
  setRole,
  setRoleList,
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(role),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Role created:", data);
      setCustomMessage("Role created");
      setRole({
        Name: "",
        Code: "",
        Description: "",
      });
      const data2 = await fetchData(apiUrl, sessionToken);
      setRoleList(data2.value);
    } else {
      notify("Error creating role");
      throw Error("Failed to create role");
    }
  } catch (error) {
    notify("Error creating role");
    console.error("Error creating role:", error);
  }
};

export const assignRoleToUser = async (
  apiUrl,
  sessionToken,
  selectedUserId,
  selectedRoleId,
  setCustomMessage
) => {
  try {
    if (selectedUserId && selectedRoleId) {
      const assignRoleApi = `${apiUrl}`;
      const assignData = {
        userId: selectedUserId,
        roleId: selectedRoleId,
      };
      console.log(assignData)
      const response = await fetch(assignRoleApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      });
      if (response.ok) {
        setCustomMessage("Role assigned");
        console.log("Role assigned to user successfully");
      } else {
        console.error("Error assigning role to user. Status:", response.status);
        notify("Error assigning role to user");
      }
    }
  } catch (error) {
    console.error("Error assigning role to user:", error);
    notify("Error assigning role to user");
  }
};
