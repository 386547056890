import { fetchData } from "../Utils/ReusableFunctions";

export const updateCustomer = async (
  apiUrl,
  sessionToken,
  selectedCustomer,
  fetchData
) => {
  if (!selectedCustomer) {
    throw new Error("No customer selected");
  }
  const patchUrl = `${apiUrl}(${selectedCustomer.ID})`;
  const patchData = {
    Name: selectedCustomer.Name,
  };
  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });
  if (!response.ok) {
    throw new Error(`Error updating customer. Status: ${response.status}`);
  }
  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};

export const createCustomer = async (
  apiUrl,
  sessionToken,
  customer,
  notify,
  setCustomMessage,
  setCustomer,
  setCustomerList
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(customer),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      setCustomMessage("Customer created");
      setCustomer({
        PriceGroupID: "",
        CurrencyID: "",
        Code: "",
        Name: "",
      });
      fetchData(apiUrl, sessionToken).then((data) => {
        setCustomerList(data.value);
      });
    } else {
      notify("Error creating customer");
      throw Error("Failed to create tenant");
    }
  } catch (error) {
    notify("Error creating customer");
    console.error("Error creating tenant:", error);
  }
};
