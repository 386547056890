import { toast } from "react-toastify";

export const fetchData = async (api, sessionToken) => {
  try {
    const response = await fetch(api, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
};

export const filterListByName = (list, searchString) => {
  return list.filter((item) =>
    item.Name.toLowerCase().includes(searchString.toLowerCase())
  );
};

export const handleListNavigation = (
  event,
  filteredList,
  selectedItem,
  setSelectedItem
) => {
  const currentIndex = filteredList.findIndex(
    (item) => item.ID === selectedItem?.ID
  );

  switch (event.key) {
    case "ArrowUp":
      event.preventDefault();
      const newIndexUp = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      setSelectedItem(filteredList[newIndexUp]);
      break;
    case "ArrowDown":
      event.preventDefault();
      const newIndexDown =
        currentIndex < filteredList.length - 1
          ? currentIndex + 1
          : currentIndex;
      setSelectedItem(filteredList[newIndexDown]);
      break;
    case "Escape":
      setSelectedItem(null);
      break;
    default:
      break;
  }
};

export const notify = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const getProperties = async (
  propertiesApi,
  sessionToken,
  languageOptions,
  setProperties
) => {
  try {
    const requestBody = {
      Culture: languageOptions,
    };
    const response = await fetch(propertiesApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    setProperties(data.Properties);
  } catch (error) {
    console.error("Error:", error);
  }
};
