import "./Product.css";
import { useProductList } from "../../Context/ProductListContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCart } from "../../Context/CartContext";
import nophoto from "../../Images/nophotosmall.png";
import { useNavigate } from "react-router-dom";
import { CircleFlag } from "react-circle-flags";
import React, { useEffect } from "react";

export const Products = ({
  handleQuantityChange,
  productQuantities,
  setProductQuantities,
  formatCurrency,
}) => {
  const {
    productImages,
    setShowItems,
    productList,
    prices,
    showItems,
    fetchProductList,
    fetchProductImages,
  } = useProductList();
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const initialQuantities = {};
    productList.forEach((product) => {
      initialQuantities[product.ID] = product.CaseQty;
    });
    setProductQuantities(initialQuantities);
  }, [productList, setProductQuantities]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setShowItems((prevValue) => prevValue + 25);
      sessionStorage.setItem("showItems", showItems + 25);
    }, 1200);
  };

  const addItemsToCart = (productId, qty) => {
    addToCart(productId, qty);
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: productList.find((product) => product.ID === productId)
        .CaseQty,
    }));
  };

  const handleProductClick = (productId) => {
    navigate(`/shop/product/${productId}`);
  };

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  useEffect(() => {
    fetchProductImages();
  }, [fetchProductImages]);

  return (
    <div className="container">
      <InfiniteScroll
        dataLength={productList.length}
        next={fetchMoreData}
        hasMore={true}
        style={{ overflow: "hidden" }}
      >
        <div className="row row-cols-1 row-cols-md-2 row-cols-sm-2 row-cols-lg-3">
          {productList.map((product) => {
            const productImagesForCurrentProduct = productImages[product.ID];
            const firstImage = productImagesForCurrentProduct
              ? productImagesForCurrentProduct[0]
              : { imageUrl: nophoto };
            const propertiesWithID3 = product.Properties.filter(
              (property) => property.PropertyID === 3
            );
            const productPrice = prices.find(
              (price) => price.productId === product.ID
            );

            if (!productPrice) {
              return (
                <div key={product.ID} className="col mb-4">
                  <div className="card rounded-0 border-0">
                    <div className="mx-auto mt-4">
                      <p>Loading...</p>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div key={product.ID} className="col mb-4">
                <div className="card rounded-0 border-0">
                  <div className="d-flex justify-content-center">
                    <img
                      className={`productImage m-2 mt-3 ${
                        firstImage ? "loaded" : "unloaded"
                      }`}
                      src={firstImage.imageUrl}
                      alt="game"
                      onClick={() => handleProductClick(product.ID)}
                      style={{ height: "300px" }}
                    />
                  </div>
                  <div className="card-body">
                    <h5
                      className="card-title"
                      onClick={() => handleProductClick(product.ID)}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product.Name}
                    </h5>
                  </div>
                  <ul className="list-group list-group-flush fw-medium">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>{product.Code}</span>
                      <div>
                        {propertiesWithID3.map((property) => {
                          const flagMap = {
                            Norwegian: "no",
                            Danish: "dk",
                            Swedish: "se",
                            English: "gb",
                            Finnish: "fi",
                          };
                          const countryCode =
                            flagMap[property.PropertyValue.Name];
                          if (countryCode) {
                            return (
                              <CircleFlag
                                countryCode={countryCode}
                                height="20"
                                className="ms-1"
                                key={`${property.PropertyValue.ID}-${countryCode}`}
                              />
                            );
                          }
                          return (
                            <span key={property.PropertyValue.ID}>
                              {" "}
                              {property.PropertyValue.Name}{" "}
                            </span>
                          );
                        })}
                      </div>
                    </li>

                    <li className="list-group-item">
                      DKK: {formatCurrency(productPrice.price)}
                    </li>

                    <li className="list-group-item input-group">
                      <div className="input-group">
                        <input
                          id={product.ID}
                          min={1}
                          type="number"
                          className="form-control rounded-0 border-end-0"
                          placeholder={product.CaseQty.toString()}
                          aria-label="Quantity"
                          aria-describedby="basic-addon2"
                          value={productQuantities[product.ID] || ""}
                          onChange={(e) =>
                            handleQuantityChange(product.ID, e.target.value)
                          }
                        />
                        <span
                          className="input-group-text rounded-0 border-start-0"
                          onClick={() => {
                            addItemsToCart(
                              product.ID,
                              productQuantities[product.ID]
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="bi bi-cart4"
                            style={{ fontSize: "1.3rem" }}
                          ></i>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};
