export const ApiUrls = {
  userApi: "https://shop.decentra.net/odata/User?$expand=roles",
  userPropertiesApi: "https://shop.decentra.net/odata/user/properties",
  roleApi: "https://shop.decentra.net/odata/ROle",
  userRoleApi: "https://shop.decentra.net/odata/UserRole",
  rolePropertiesApi: "https://shop.decentra.net/odata/role/properties",
  currencyPropertiesApi: "https://shop.decentra.net/odata/Currency/properties",
  currencyApi: "https://shop.decentra.net/odata/Currency",
  lanugageApi: "https://shop.decentra.net/odata/Language",
  languagePropertiesApi:  "https://shop.decentra.net/odata/language/properties",
  customerApi: "https://shop.decentra.net/odata/customer",
  customerPropertiesApi:  "https://shop.decentra.net/odata/customer/properties",
  priceGroupApi: "https://shop.decentra.net/odata/pricegroup",
  priceGroupPropertiesApi: "https://shop.decentra.net/odata/pricegroup/properties",
  productsApi: "https://shop.decentra.net/odata/product",
  productsPropertiesApi: "https://shop.decentra.net/odata/product/properties",
  productDiscountGroupApi: "https://shop.decentra.net/odata/ProductDiscountGroup",
  productImageApi: "https://shop.decentra.net/odata/productImage",
  getProductImageApi: "https://shop.decentra.net/odata/GetProductImage",
  uploadProductImageApi: "https://shop.decentra.net/odata/ProductImage/UploadImage",
  productWithImageApi: "https://shop.decentra.net/odata/product?&top=20&skip=0&orderby=id&expand=images($select=id;$orderby=sortorder;)",
  addProductToCartApi: "https://shop.decentra.net/odata/cart/add",
  getCartItems: "https://shop.decentra.net/odata/cart",
  getCartItemsWithExpanding:"https://shop.decentra.net/odata/cart?$expand=product",
  showPriceProduct: "https://shop.decentra.net/odata/Product",
  orderComplete: "https://shop.decentra.net/odata/cart/CreateOrder",
  getOrder: "https://shop.decentra.net/odata/order/",
  properties: "https://shop.decentra.net/odata/property",
  propertyProperties: "https://shop.decentra.net/odata/property/properties",
  propertyValues: "https://shop.decentra.net/odata/propertyValue",
  productPrice: "https://shop.decentra.net/odata/ProductPrice",
  productPropertyPost: "https://shop.decentra.net/odata/ProductProperty",




  





};
/*
  export const ApiUrls = {
    apikeyApi: "https://auth.decentra.net/odata/apikey",
    applicationApi: "https://auth.decentra.net/odata/application",
    userApplicationApi: "https://auth.decentra.net/odata/userapplication",
    countryApi: "https://auth.decentra.net/odata/Country",
    lanugageApi: "https://auth.decentra.net/odata/Language",
    roleApi: "https://auth.decentra.net/odata/ROle",
    userRoleApi: "https://auth.decentra.net/odata/UserRole",
    customerApi: "https://shop.decentra.net/odata/customer",
    getUserWithRoleApi:
      "https://auth.decentra.net/odata/User?$expand=Roles($expand=Role($select=Code))",
    userApi: "https://auth.decentra.net/odata/User",
    currencyApi:  "https://auth.decentra.net/odata/Currency",
    applicationPropertiesAPi: "https://auth.decentra.net/odata/application/properties",
    apikeyPropertiesApi: "https://auth.decentra.net/odata/apikey/properties",
    countryPropertiesApi: "https://auth.decentra.net/odata/country/properties",
    userPropertiesApi:  "https://auth.decentra.net/odata/user/properties",
    tenantPropertiesApi:  "https://auth.decentra.net/odata/tenant/properties",
    rolePropertiesApi:  "https://shop.decentra.net/odata/role/properties",
    languagePropertiesApi:  "https://auth.decentra.net/odata/language/properties",
    currencyPropertiesApi:  "https://shop.decentra.net/odata/Currency/properties",
  };
  */
