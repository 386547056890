import React, { createContext, useContext, useState } from 'react';

const ShowInfo = createContext();

export const useShowInfo = () => {
  return useContext(ShowInfo);
};

export const ActiveShowInfo = ({ children }) => {
  const [customMessage, setCustomMessage] = useState('');
  const [languageOptions, setLanguageOptions] = useState("en")


  return (
    <ShowInfo.Provider value={{languageOptions, setLanguageOptions, customMessage, setCustomMessage}}>
      {children}
    </ShowInfo.Provider>
  );
};