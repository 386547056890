import { fetchData } from "../Utils/ReusableFunctions";

export const updatePriceGroup = async (
  apiUrl,
  sessionToken,
  selectedPriceGroup,
  fetchData
) => {
  if (!selectedPriceGroup) {
    throw new Error("No Price Group selected");
  }

  const patchUrl = `${apiUrl}(${selectedPriceGroup.ID})`;
  const patchData = {
    Code: selectedPriceGroup.Code,
    Name: selectedPriceGroup.Name,
    ID: selectedPriceGroup.ID,
  };

  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });

  if (!response.ok) {
    throw new Error(`Error updating price group. Status: ${response.status}`);
  }

  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};

export const createPriceGroup = async (
  apiUrl,
  sessionToken,
  priceGroup,
  notify,
  setCustomMessage,
  setPriceGroup,
  setPriceGroupList
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(priceGroup),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("Price group created:", data);
      setCustomMessage("Price group created");
      setPriceGroup({
        Code: "",
        Name: "",
      });
      fetchData(apiUrl, sessionToken).then((data) => {
        setPriceGroupList(data.value);
      });
    } else {
      notify("Error creating price group");
      throw Error("Failed to create price group");
    }
  } catch (error) {
    notify("Error creating price group");
    console.error("Error creating price group:", error);
  }
};
