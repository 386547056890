import React, { useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import AsyncSelect from "react-select";
import { createProduct } from "../../DataService/ProductsDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";

export const CreateProduct = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setCustomMessage } = useShowInfo();
  const [selectedValue, setSelectedValue] = useState(null);
  const [productDiscountGroupList, setProductDiscountGroupList] = useState([]);
  const [product, setProduct] = useState({
    ProductDiscountGroupID: "",
    Active: true,
    Name: "",
    Code: "",
    Inventory: "",
    Description: "",
    CaseQty: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const floatValue = ["CaseQty", "Inventory"].includes(name)
      ? parseFloat(value)
      : value;
    setProduct((prevUser) => ({
      ...prevUser,
      [name]: floatValue,
    }));
  };

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setProduct((prevUser) => ({
      ...prevUser,
      ProductDiscountGroupID: selectedOption.value,
    }));
  };
  const getProductDiscountGroup = () => {
    fetchData(ApiUrls.productDiscountGroupApi, sessionToken).then((data) => {
      setProductDiscountGroupList(data.value);
    });
  };

  const handleCreateProduct = () => {
    createProduct(
      ApiUrls.productsApi,
      sessionToken,
      product,
      notify,
      setCustomMessage,
      setProduct,
      setSelectedValue
    );
  };

  return (
    <div
    className="modal fade"
    id="createProductModal"
    aria-labelledby="createProductModal"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="createProductModal">
            Create product
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
        <div className="input-group mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Code"
      name="Code"
      value={product.Code}
      onChange={handleInputChange}
    />
  </div>
  <div className="input-group mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Name"
      name="Name"
      value={product.Name}
      onChange={handleInputChange}
    />
  </div>
  <div className="mb-2">
    <textarea
      value={product.Description}
      onChange={handleInputChange}
      placeholder="Description"
      className="form-control"
      id="exampleFormControlTextarea1"
      rows="3"
      Name="Description"
    ></textarea>
  </div>
  <AsyncSelect
    placeholder="Choose price group"
    className="mb-2"
    id="tenantSelect"
    value={selectedValue}
    onChange={handleChange}
    options={productDiscountGroupList.map((group) => ({
      value: group.ID,
      label: group.Name,
    }))}
    onMenuOpen={() => {
      getProductDiscountGroup();
    }}
    styles={{
      control: (provided) => ({
        ...provided,
        textAlign: "left",
      }),
      option: (provided) => ({
        ...provided,
        textAlign: "left",
      }),
    }}
  />
  <div className="input-group mb-2">
    <input
      type="number"
      className="form-control"
      placeholder="Inventory"
      name="Inventory"
      value={product.Inventory}
      onChange={handleInputChange}
    />
  </div>
  <div className="input-group mb-2">
    <input
      type="number"
      className="form-control"
      placeholder="CaseQty"
      name="CaseQty"
      value={product.CaseQty}
      onChange={handleInputChange}
    />
  </div>
        </div>
        <div className="modal-footer">
          <button
            type="button "
            className="btn btn-secondary btn-sm"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary  btn-sm"
            onClick={handleCreateProduct}
            data-bs-dismiss="modal"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};


