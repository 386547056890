import React, { useEffect, useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useProductList } from "../../Context/ProductListContext";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export const FilterProducts = ({ classParameter, idPara, setSelectedSort }) => {
  const sessionToken = localStorage.getItem("bearerToken");

  const [properties, setProperties] = useState([]);
  const [propertiesValue, setPropertiesValue] = useState([]);
  const {
    addPropertyFilter,
    removePropertyFilter,
    propertyFilters,
    resetFilters,
    setSliderValue,
    sliderValue,
    setShowItems,
  } = useProductList();

  useEffect(() => {
    fetchData("https://shop.decentra.net/odata/property", sessionToken)
      .then((data) => {
        setProperties(data.value);
      })
      .catch((error) => {
        console.error("Error fetching property list:", error);
      });

    fetchData("https://shop.decentra.net/odata/propertyValue", sessionToken)
      .then((data) => {
        setPropertiesValue(data.value);
      })
      .catch((error) => {
        console.error("Error fetching propertyValue list:", error);
      });
  }, [sessionToken]);

  const filteredProperties = properties.filter(
    (property) => property.FilterOrder !== 0 && property.FilterOrder !== null
  );

  const sortedProperties = filteredProperties.sort(
    (a, b) => a.FilterOrder - b.FilterOrder
  );

  const getPropertyValuesByPropertyID = (propertyID) => {
    return propertiesValue.filter((value) => value.PropertyID === propertyID);
  };

  const handleCheckboxChange = (propertyId, propertyValueId) => {
    const filterExists = propertyFilters.some(
      (filter) =>
        filter.propertyId === propertyId &&
        filter.propertyValueId === propertyValueId
    );

    if (filterExists) {
      removePropertyFilter(propertyId, propertyValueId);
    } else {
      addPropertyFilter(propertyId, propertyValueId);
    }
  };

  const handleResetFilters = () => {
    resetFilters();
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setShowItems(25);
    sessionStorage.removeItem("showItems");

    setSelectedSort("");
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleInputChange = (index, newValue) => {
    const newSliderValue = [...sliderValue];
    newSliderValue[index] = Number(newValue);
    setSliderValue(newSliderValue);
  };

  return (
    <div>
      <nav
        id="sidebarMenu"
        className={`${classParameter} d-lg-block sidebar mt-2 bg-white`}
      >
        <small
          className="fw-bolder ms-4"
          style={{ fontSize: "0.7rem", cursor: "pointer" }}
          onClick={handleResetFilters}
        >
          RESET FILTERS / SORTING
        </small>
        <div
          className="list-group list-group-flush "
          style={{ textAlign: "left" }}
        >
          <div className="accordion accordion-flush" id="accordionFilter">
            {sortedProperties.map((property, index) => (
              <div className="accordion-item" key={index}>
                <div className="accordion-header">
                  <button
                    className="accordion-button collapsed fw-bolder"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapsePlayers${index}`}
                    aria-expanded="true"
                    aria-controls={`collapsePlayers${index}`}
                    style={{ color: "#6A6A6A" }}
                  >
                    {property.Name}
                  </button>
                </div>
                <div
                  id={`collapsePlayers${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFilter"
                >
                  <div className="accordion-body">
                    {property.Name === "Number of players" ? (
                      <>
                        <Slider
                          range
                          step={1}
                          min={1}
                          max={100}
                          value={sliderValue}
                          onChange={handleSliderChange}
                        />
                        <div className="d-flex justify-content-between align-items-start">
                    
                        </div>
                        <div className="row rounded-0">
                          <div className="col-6">
                            <label htmlFor={`minSlidingValue-${idPara}`}>
                              From:{" "}
                            </label>
                            <input
                              id={`minSlidingValue-${idPara}`}
                              value={sliderValue[0]}
                              className="w-50"
                              onChange={(e) =>
                                handleInputChange(0, e.target.value)
                              }
                            />
                          </div>
                          <div className="col-6">
                            <label htmlFor={`maxSlidingValue-${idPara}`}>
                              To:{ " "} 
                            </label>
                            <input
                              id={`maxSlidingValue-${idPara}`}
                              value={sliderValue[1]}
                              className="w-50"
                              onChange={(e) =>
                                handleInputChange(1, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      getPropertyValuesByPropertyID(property.ID).map(
                        (value, valueIndex) => (
                          <div key={valueIndex} className="form-check">
                            <input
                              type="checkbox"
                              value={value.Value}
                              name={`players${index}`}
                              id={`player${value.PropertyID}-${valueIndex}-${idPara}`}
                              onChange={() =>
                                handleCheckboxChange(property.ID, value.ID)
                              }
                            />
                            <label
                              className="form-check-label fw-medium"
                              htmlFor={`player${value.PropertyID}-${valueIndex}-${idPara}`}
                            >
                              {value.Name}
                            </label>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};
