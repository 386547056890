import { useCallback, useEffect, useState } from "react";
import {
  fetchData,
  handleListNavigation,
  filterListByName,
  getProperties,
  notify,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useShowInfo } from "../../Context/ShowInfo";
import { updateCurrency } from "../../DataService/CurrencyDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { CreateCurrency } from "./CreateCurrency";

export const Currency = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { languageOptions, setCustomMessage } = useShowInfo();
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [properties, setProperties] = useState([]);

  const filteredList = filterListByName(currencyList, searchString);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.currencyPropertiesApi,
      sessionToken,
      languageOptions,
      setProperties
    );
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.currencyApi, sessionToken).then((data) => {
      setCurrencyList(data.value);
      console.log(data);
      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedCurrency,
        setSelectedCurrency
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedCurrency]);

  const handleSave = async () => {
    try {
      const updatedCurrencyList = await updateCurrency(
        ApiUrls.currencyApi,
        sessionToken,
        selectedCurrency,
        fetchData
      );
      console.log("Currency updated successfully");
      setCustomMessage("Currency updated successfully");
      setCurrencyList(updatedCurrencyList);
    } catch (error) {
      notify("Error updating currency");
      console.error("Error updating currency:", error);
    }
  };

  const handleChange = (field, value) => {
    setSelectedCurrency((prevCurrency) => ({
      ...prevCurrency,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="d-flex align-items-center">
              Currency
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#currencyModal"
              >
          <i  className="bi bi-plus-circle-dotted" style={{fontSize:"1rem"}}></i>
              </button>
            </h5>
            <div>
              <input
                id="currencySearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedCurrency?.ID
                      ? "list-group-item active"
                      : "list-group-item "
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedCurrency(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedCurrency && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Currency Details</h5>
              </div>
              <div className="form-floating">
                <MDBInput
                  name="Code"
                  id="Code"
                  wrapperClass="mb-3"
                  className="form-control"
                  value={selectedCurrency.Code}
                  onChange={(e) => handleChange("Code", e.target.value)}
                  label={properties[0].Label}
                  type="text"
                  size="md"
                  onFocus={() =>
                    handleInputSelect(true, properties[0].Description)
                  }
                  onBlur={() => handleInputSelect(false)}
                />
              </div>
              <div className="form-floating">
                <MDBInput
                  name="currencyName"
                  id="currencyName"
                  wrapperClass="mb-2"
                  className="form-control"
                  value={selectedCurrency.Name}
                  onChange={(e) => handleChange("Name", e.target.value)}
                  label={properties[1].Label}
                  type="text"
                  size="md"
                  onFocus={() =>
                    handleInputSelect(true, properties[1].Description)
                  }
                  onBlur={() => handleInputSelect(false)}
                />
              </div>
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateCurrency setCurrencyList={setCurrencyList} />
    </div>
  );
};
