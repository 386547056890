import PropTypes from "prop-types";
import { createContext, useState, useContext } from "react";
import { ApiUrls } from "../DataService/ApiUrlsDataService";
import { fetchData } from "../Utils/ReusableFunctions";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const sessionToken = localStorage.getItem("bearerToken");
  const [productQuantities, setProductQuantities] = useState({});


    const fetchCartItems = async () => {
      try {
        const response = await fetchData(
          ApiUrls.getCartItemsWithExpanding,
          sessionToken
        );
        setCartItems(response.value);
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };
    


  const addToCart = async (productID, qty = 1) => {
    const apiUrl = `${ApiUrls.addProductToCartApi}(ProductID=${productID},Qty=${qty})`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add to cart");
      } else {
        const updatedCartItems = await fetchData(
          ApiUrls.getCartItems,
          sessionToken
        );
        setCartItems(updatedCartItems.value);
        console.log("Item added successfully");
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const addOrRemoveFromCart = async (item, quantity) => {
    try {
      const newQuantity = item.Qty + quantity;
      if (newQuantity < 1) {
        removeFromCart(item);
        return;
      }
      console.log(newQuantity);
      const apiUrl = `${ApiUrls.getCartItems}(${item.ProductID})/Set(Qty=${newQuantity})?CartID=${item.ID}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to update item quantity in cart");
      }

      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.ID === item.ID ? { ...cartItem, Qty: newQuantity } : cartItem
        )
      );
    } catch (error) {
      console.error("Error updating item quantity in cart:", error);
    }
  };

  const removeFromCart = async (item) => {
    try {
      const response = await fetch(`${ApiUrls.getCartItems}/${item.ID}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to remove item from cart");
      }

      setCartItems(cartItems.filter((cartItem) => cartItem.ID !== item.ID));
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        addToCart,
        removeFromCart,
        addOrRemoveFromCart,
        productQuantities,
        setProductQuantities,
        fetchCartItems
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCart = () => useContext(CartContext);
