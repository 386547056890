import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { NavigationBar } from "./NavigationBar";
import { SideBar } from "./Sidebar";
import { Products } from "../Products/Products";
import { ProductPage } from "../Products/ProductPage";
import { RouteComponent } from "../RouteComponent/RouteComponent";
import { Cart } from "../Cart/Cart";
import { FilterAndSortContainer } from "../FilterAndSort/FilterAndSortContainer";
import "./ProductsContainer.css";
import { useProductList } from "../../Context/ProductListContext";
import { Checkout } from "../Checkout/Checkout";
import { OrderConfirmation } from "../Checkout/OrderConfirmation";

export const ProductsContainer = () => {
  const [isContainerVisible, setContainerVisible] = useState(true);
  const { productList } = useProductList();
  const [productQuantities, setProductQuantities] = useState({});
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setContainerVisible(window.innerWidth >= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleQuantityChange = (productId, quantity) => {
    quantity = parseInt(quantity, 10);
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency: "DKK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const hideNavAndFilters =
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/orderconfirmation");
  const hideFilters = location.pathname.includes("/cart");

  return (
    <div className={`App ${isContainerVisible ? "container" : ""}`}>
      <div>
        {!hideNavAndFilters && <NavigationBar />}
        {!hideNavAndFilters && !hideFilters && (
          <div className="filterAndSort">
            <FilterAndSortContainer />
          </div>
        )}
        <Routes>
          <Route
            path="/"
            element={
              <div className="content-wrapper">
                <SideBar />
                <div className="content">
                  <Products
                    productQuantities={productQuantities}
                    handleQuantityChange={handleQuantityChange}
                    setProductQuantities={setProductQuantities}
                    formatCurrency={formatCurrency}
                  />
                </div>
              </div>
            }
          />
          <Route
            path="/product/:id"
            element={
              <ProductPage
                productQuantities={productQuantities}
                handleQuantityChange={handleQuantityChange}
                products={productList}
                setProductQuantities={setProductQuantities}
                formatCurrency={formatCurrency}
              />
            }
          />
          <Route path="/system/*" element={<RouteComponent />} />
        </Routes>

        <Routes>
          <Route
            path="/cart"
            element={<Cart formatCurrency={formatCurrency} />}
          />
          <Route path="/checkout" element={<Checkout formatCurrency={formatCurrency} />} />
          <Route path="/orderconfirmation" element={<OrderConfirmation />} />
        </Routes>

      </div>
    </div>
  );
};
