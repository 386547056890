import { useNavigate } from "react-router-dom";

export const AdminList = () => {
  const navigate = useNavigate();

  return (
    <div>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/roles")}
        >
          ROLES
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/users")}
        >
          USERS
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/currencies")}
        >
          CURRENCY
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/languages")}
        >
          LANGUAGE
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/customers")}
        >
          CUSTOMERS
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/properties")}
        >
          PROPERTIES
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/pricegroups")}
        >
          PRICE GROUPS
        </span>
      </li>
      <li>
        <span
          className="dropdown-item mb-1 fw-medium"
          onClick={() => navigate("/shop/system/products")}
        >
          PRODUCTS
        </span>
      </li>
    </div>
  );
};
