import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  handleListNavigation,
  getProperties,
  notify,
} from "../../Utils/ReusableFunctions";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useShowInfo } from "../../Context/ShowInfo";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import AsyncSelect from "react-select";
import "./ProductsList.css";
import {
  fetchProductImages,
  updateProduct,
  fetchDataAndUpdateState,
  readFileAsBase64,
} from "../../DataService/ProductsDataService";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductsPropertyModal } from "./ProductPropertyModal";
import { CreateProduct } from "./CreateProduct";

export const ProductsList = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { setCustomMessage, languageOptions } = useShowInfo();
  const [properties, setProperties] = useState([]);
  const [productDiscountGroupList, setProductDiscountGroupList] = useState([]);
  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showItems, setShowItems] = useState(25);
  const [searchVariable, setSearchVariable] = useState("");
  const [productList, setProductList] = useState([]);
  const [productImages, setProductImages] = useState({});
  const [includeImages, setIncludeImages] = useState(true);
  const currentProductImages = selectedProduct
    ? productImages[selectedProduct.ID]
    : null;

  const generateAPIURL = () => {
    let apiUrl;
    if (includeImages) {
      apiUrl = `${ApiUrls.productsApi}?$top=${showItems}&orderby=id&$expand=images($select=id,SortOrder;$orderby=sortorder;)&$filter=contains(Name,'${searchVariable}') or contains(Code,'${searchVariable}')`;
    } else {
      apiUrl = `${ApiUrls.productsApi}?$top=${showItems}&orderby=id&$filter=not images/any() and (contains(Name,'${searchVariable}') or contains(Code,'${searchVariable}'))`;
    }
    return apiUrl;
  };

  const handleSwitchChange = () => {
    setSelectedProduct(null);
    setIncludeImages(!includeImages);
  };

  const apiUrl = generateAPIURL(includeImages);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.productsPropertiesApi,
      sessionToken,
      languageOptions,
      setProperties
    );
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchDataAndUpdateState(apiUrl, sessionToken, setProductList);
  }, [apiUrl, sessionToken]);

  useEffect(() => {
    fetchProductImages(productList, sessionToken, ApiUrls, setProductImages);
  }, [productList, sessionToken, selectedProduct]);

  useEffect(() => {
    fetchDataAndUpdateState(
      ApiUrls.productDiscountGroupApi,
      sessionToken,
      setProductDiscountGroupList
    );
    handleGetProperties();
  }, [sessionToken, handleGetProperties]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setShowItems((prevValue) => prevValue + 25);
    }, 500);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        productList,
        selectedProduct,
        setSelectedProduct
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [productList, selectedProduct]);

  const handleChange = (field, value) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDropImage = async (event) => {
    try {
      event.preventDefault();

      if (dragItemIndex !== undefined) {
        return;
      }

      const droppedFiles = Array.from(event.dataTransfer?.files || []);
      if (droppedFiles.length === 0) {
        throw new Error("No files dropped.");
      }
      if (!selectedProduct) {
        throw new Error("No product selected.");
      }

      const imageUploadPromises = droppedFiles.map(async (file) => {
        if (!file.type.startsWith("image/")) {
          throw new Error("Only image files are allowed to be dropped.");
        }

        const base64String = await readFileAsBase64(file);
        const nextSortOrder = await getNextSortOrder();
        const imageInfo = {
          productID: selectedProduct.ID,
          sortOrder: nextSortOrder,
          imageData: base64String,
        };

        const createResponse = await fetch(ApiUrls.productImageApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify(imageInfo),
        });

        if (!createResponse.ok) {
          throw new Error("Failed to create image");
        }

        const data = await createResponse.json();
        const imageUrl = `data:image/png;base64,${data.ImageData}`;

        return {
          imageUrl: imageUrl,
          imageID: data.ID,
          SortOrder: data.SortOrder,
        };
      });

      const uploadedImages = await Promise.all(imageUploadPromises);

      setProductImages((prevProductImages) => ({
        ...prevProductImages,
        [selectedProduct.ID]: [
          ...(prevProductImages[selectedProduct.ID] || []),
          ...uploadedImages,
        ],
      }));

      console.log(productImages);
    } catch (error) {
      console.error("Error handling drop and adding image:", error);
    }
  };

  const getNextSortOrder = async () => {
    const existingImages = currentProductImages || [];
    const nextSortOrder = existingImages.length + 1;
    return nextSortOrder;
  };

  const handleDeleteImage = async (imageId) => {
    try {
      const response = await fetch(ApiUrls.productImageApi + `/${imageId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.ok) {
        console.log("Image deleted successfully");
        setProductImages((prevProductImages) => {
          const updatedProductImages = { ...prevProductImages };
          for (const productId in updatedProductImages) {
            updatedProductImages[productId] = updatedProductImages[
              productId
            ].filter((image) => image.imageID !== imageId);
            updatedProductImages[productId] = updatedProductImages[
              productId
            ].map((image, idx) => ({
              ...image,
              SortOrder: idx + 1,
            }));
          }
          saveSortOrderToBackend(updatedProductImages);
          return updatedProductImages;
        });
      } else {
        throw new Error("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleDragStartOrder = (index) => {
    setDragItemIndex(index);
  };
  const handleDragOverOrder = (event) => {
    event.preventDefault();
  };

  const handleDropOrder = (index) => {
    if (dragItemIndex !== undefined && dragOverItemIndex !== undefined) {
      const updatedProductImages = { ...productImages };
      const productId = selectedProduct.ID;
      const productImagesArray = updatedProductImages[productId];
      if (Array.isArray(productImagesArray)) {
        const draggedItem = productImagesArray.splice(dragItemIndex, 1)[0];
        const newIndex =
          dragItemIndex < dragOverItemIndex
            ? dragOverItemIndex - 1
            : dragOverItemIndex;

        productImagesArray.splice(newIndex, 0, draggedItem);
        const reorderedImagesWithSortOrder = productImagesArray.map(
          (image, idx) => ({
            ...image,
            SortOrder: idx + 1,
          })
        );

        updatedProductImages[productId] = reorderedImagesWithSortOrder;
        setProductImages(updatedProductImages);
        console.log(reorderedImagesWithSortOrder);
        saveSortOrderToBackend(reorderedImagesWithSortOrder);
      }
    }

    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const saveSortOrderToBackend = async (productImages) => {
    try {
      const images = Object.values(productImages).flatMap(
        (imagesArray) => imagesArray
      );
      const sortedImages = images.sort((a, b) => a.SortOrder - b.SortOrder);
      const payload = sortedImages.map((image) => ({
        imageID: image.imageID,
        SortOrder: image.SortOrder,
      }));
      for (const image of payload) {
        const response = await fetch(
          `${ApiUrls.productImageApi}/${image.imageID}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionToken}`,
            },
            body: JSON.stringify({ SortOrder: image.SortOrder }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to update sort order for image ${image.imageID}`
          );
        }
      }
      console.log("Sort order updated successfully");
    } catch (error) {
      console.error("Error updating sort order:", error);
    }
  };

  const handleDragEnterOrder = (index) => {
    setDragOverItemIndex(index);
  };
  const handleDragLeaveOrder = (event) => {
    setDragOverItemIndex(undefined);
  };
  const handleDragEndOrder = (event) => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const handleSaveProduct = () => {
    updateProduct(
      ApiUrls.productsApi,
      sessionToken,
      selectedProduct,
      fetchData,
      setProductList,
      notify,
      setCustomMessage
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="d-flex align-items-center">
              Products
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#createProductModal"
              >
                <i
                  className="bi bi-plus-circle-dotted"
                  style={{ fontSize: "1rem" }}
                ></i>
              </button>
            </h5>
            <div className="form-check  form-switch mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="includeImagesSwitch"
                checked={includeImages}
                onChange={handleSwitchChange}
              />
              <label className="form-check-label" htmlFor="includeImagesSwitch">
                Include Images
              </label>
            </div>
            <div>
              <input
                id="productSearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchVariable(e.target.value)}
              />
            </div>
          </div>
          <div
            className="list-container"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <InfiniteScroll
              dataLength={productList.length}
              next={fetchMoreData}
              hasMore={true}
              height={400}
            >
              <div className="list-group small" style={{ textAlign: "left" }}>
                {productList.map((item) => (
                  <div
                    className={
                      item.ID === selectedProduct?.ID
                        ? "list-group-item active"
                        : "list-group-item "
                    }
                    key={item.ID}
                    aria-current="true"
                    onClick={() => setSelectedProduct(item)}
                  >
                    <div className="d-flex justify-content-between">
                      <div>{item.Name}</div>
                      <small>{item.Code}</small>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedProduct && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Products details</h5>
              </div>
              <MDBInput
                name="Code"
                id="code"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedProduct.Code}
                onChange={(e) => handleChange("Code", e.target.value)}
                label={properties[3].Label}
                type="text"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[2].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBInput
                name="productName"
                id="productName"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedProduct.Name}
                onChange={(e) => handleChange("Name", e.target.value)}
                label={properties[6].Label}
                type="text"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[5].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBTextArea
                wrapperClass="mb-2"
                value={selectedProduct.Description}
                label={properties[7].Label}
                id="textAreaExample"
                rows={4}
                onChange={(e) => handleChange("Description", e.target.value)}
                onFocus={() =>
                  handleInputSelect(true, properties[6].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <div
                className="form-check form-switch text-start mb-2"
                style={{
                  border: "solid",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  borderColor: "#BDBDBD",
                }}
              >
                <div className="m-1" style={{ height: "24px" }}>
                  <input
                    name="active"
                    checked={selectedProduct.Active}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="active"
                    onChange={(e) => handleChange("Active", e.target.checked)}
                    onFocus={() =>
                      handleInputSelect(true, properties[2].Description)
                    }
                    onBlur={() => handleInputSelect(false)}
                  />
                  <label className="form-check-label" htmlFor="active">
                    {properties[2].Name}
                  </label>
                </div>
              </div>
              <AsyncSelect
                placeholder={
                  productDiscountGroupList.find(
                    (product) =>
                      product.ID === selectedProduct.ProductDiscountGroupID
                  )?.Name
                }
                value={selectedProduct.ProductDiscountGroupID}
                className="mb-2"
                id="tenantSelect"
                onChange={(selectedOption) =>
                  handleChange("ProductDiscountGroupID", selectedOption?.value)
                }
                options={productDiscountGroupList.map((product) => ({
                  value: product.ID,
                  label: product.Name,
                }))}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    textAlign: "left",
                  }),
                  option: (provided) => ({
                    ...provided,
                    textAlign: "left",
                  }),
                }}
                onFocus={() =>
                  handleInputSelect(true, properties[0].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <div
                style={{ height: "14rem", overflowY: "auto" }}
                className="mb-2 border round-2 rounded "
                onDragOver={handleDragOver}
                onDrop={(event) => {
                  handleDropImage(event);
                }}
              >
                <div className="m-2">
                  {currentProductImages &&
                    currentProductImages.map((image, index) => (
                      <span
                        key={index}
                        className="position-relative"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        draggable
                        onDragStart={() => handleDragStartOrder(index)}
                        onDragOver={handleDragOverOrder}
                        onDrop={() => handleDropOrder(index)}
                        onDragEnter={() => {
                          handleDragEnterOrder(index);
                        }}
                        onDragLeave={handleDragLeaveOrder}
                        onDragEnd={handleDragEndOrder}
                      >
                        <img
                          className="m-2"
                          height={180}
                          src={image.imageUrl}
                          alt="productimages"
                        />
                        {hoveredIndex === index && (
                          <div className="overlay">
                            <span
                              className="delete-icon"
                              onClick={() => handleDeleteImage(image.imageID)}
                            >
                              <i className="bi bi-trash danger"></i>
                            </span>
                          </div>
                        )}
                      </span>
                    ))}
                </div>
              </div>
              <div>
                <button
                  className="mb-2 w-100 btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#productProperty"
                >
                  ASSIGN PROPERTY TO PRODUCT
                </button>
                <button
                  className="w-100 btn btn-success"
                  onClick={handleSaveProduct}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ProductsPropertyModal selectedProduct={selectedProduct} />
      <CreateProduct />
    </div>
  );
};
