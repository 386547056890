import { useEffect, useState } from "react";
import logo2 from "../../Images/logo2.png";
import logo from "../../Images/asmodee.svg";
import "./NavigationBar.css";
import { jwtDecode } from "jwt-decode";
import { AdminList } from "../AdminList/AdminList";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { useProductList } from "../../Context/ProductListContext";

export const NavigationBar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const sessionToken = localStorage.getItem("bearerToken");
  const decoded = jwtDecode(sessionToken);
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const { setSearchVariable, setLatestRelease, setOrderByRelease, setProduct } =
    useProductList();
  const decodedString = JSON.stringify(decoded);
  localStorage.setItem("decoded", decodedString);
  const [today, setToday] = useState("");
  const [last30, setLast30] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    const formattedDate = currentDate.toISOString().slice(0, 10);
    setToday(formattedDate);

    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);
    const formattedLast30DaysDate = last30DaysDate.toISOString().slice(0, 10);
    setLast30(formattedLast30DaysDate);
  }, []);

  const logOut = () => {
    sessionStorage.removeItem("showItems");
    localStorage.clear();

    navigate("/");
  };

  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom shadow-none">
        <div className="container-fluid">
          <span id="hideSUC" className="navbar-brand ms-2">
            <img
              src={logo}
              alt="Bootstrap"
              className="logo-img"
              onClick={() => {
                navigate("/shop");
              }}
            />
          </span>
          <div className="d-flex align-items-center w-100">
            <div className="row  w-100">
              <div
                className="navbar-toggler border-0 col-4"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setIsNavbarOpen(!isNavbarOpen)}
              >
                {isNavbarOpen ? (
                  <i className="bi bi-x-lg" style={{ fontSize: "1.8rem" }}></i>
                ) : (
                  <i className="bi bi-list" style={{ fontSize: "2rem" }}></i>
                )}
              </div>

              <div className="col-4 d-flex justify-content-center ">
                <div
                  className="navbar-brand h-100"
                  style={{ display: "inline-block", height: "100%" }}
                >
                  <img
                    src={logo2}
                    alt="Bootstrap"
                    id="hideUC"
                    className="logo-img"
                    onClick={() => {
                      navigate("/shop");
                    }}
                  />
                </div>
              </div>

              <div className="col-4 d-flex justify-content-end">
                {decoded.role === "SystemAdmin" && (
                  <span className="dropdown">
                    <i
                      id="hideUC"
                      className="bi bi-gear me-2"
                      data-bs-toggle="dropdown"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                    <ul className="dropdown-menu ">
                      <AdminList />
                    </ul>
                  </span>
                )}
                <i
                  id="hideUC"
                  className="bi bi-person-circle me-2"
                  style={{ fontSize: "1.5rem" }}
                ></i>
                <span className="position-relative">
                  <i
                    id="hideUC"
                    className="bi bi-cart4"
                    style={{ fontSize: "1.4rem" }}
                    onClick={() => navigate("/shop/cart")}
                  >
                    <span
                      style={{ fontSize: "0.6rem", marginTop: "0.8rem" }}
                      className="top-0 translate-middle badge rounded-pill bg-danger position-absolute"
                    >
                      {cartItems.length === 0 ? "" : cartItems.length}
                    </span>
                  </i>
                </span>
              </div>
            </div>
          </div>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <div className="">
              <div className="row" id="hideSUC">
                <div className="col-6">
                  {" "}
                  <div className="input-group ">
                    <input
                      name="searchInput"
                      type="text"
                      className="form-control rounded-0"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length >= 3) {
                          setSearchVariable(inputValue);
                        } else {
                          setSearchVariable("");
                        }
                      }}
                    />
                    <span
                      className="input-group-text rounded-0"
                      id="basic-addon2"
                    >
                      <i className="bi bi-search"></i>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  {" "}
                  <div className="ms-3 float-end">
                    {decoded.role[0] === "SystemAdmin" && (
                      <span className="dropdown">
                        <i
                          className="bi bi-gear me-3"
                          data-bs-toggle="dropdown"
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                        <ul className="dropdown-menu ">
                          <AdminList />
                        </ul>
                      </span>
                    )}

                    <span className="dropdown">
                      <i
                        className="bi bi-person-circle me-3"
                        data-bs-toggle="dropdown"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <ul className="dropdown-menu ">
                        <div>
                          <li>
                            <span
                              className="dropdown-item mb-1 fw-medium"
                              onClick={logOut}
                            >
                              LOG OUT
                            </span>
                          </li>
                        </div>
                      </ul>
                    </span>

                    <i
                      className="bi bi-cart4 me-3 position-relative"
                      style={{ fontSize: "1.4rem" }}
                      onClick={() => navigate("/shop/cart")}
                    >
                      <span
                        style={{ fontSize: "0.6rem" }}
                        className="start-50 position-absolute badge rounded-pill bg-danger"
                      >
                        {cartItems.length === 0 ? "" : cartItems.length}
                      </span>
                    </i>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ul className="navbar-nav me-auto mb-2 fw-bolder mb-lg-0 text-start text-lg-center">
                    <li className="nav-item">
                      <span
                        className="nav-link"
                        aria-current="page"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setProduct("ProductID");
                          setOrderByRelease("");
                          setLatestRelease("");
                          navigate("/shop");
                        }}
                      >
                        <span className="underline">PRODUCTS</span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderByRelease("/ReleaseDate desc, ProductID");
                          setLatestRelease(
                            `and Product/ReleaseDate ge ${last30} and Product/ReleaseDate le ${today}`
                          );
                          setProduct("Product");
                          navigate("/shop");
                        }}
                      >
                        <span className="underline">NEW</span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderByRelease("/ReleaseDate asc, ProductID");
                          setLatestRelease(
                            `and Product/ReleaseDate ge ${today}`
                          );
                          setProduct("Product");
                          navigate("/shop");
                        }}
                      >
                        <span className="underline">PREORDERS</span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderByRelease("/ReleaseDate desc, ProductID");
                          setLatestRelease("and Product/Featured eq true");
                          setProduct("Product");
                          navigate("/shop");
                        }}
                      >
                        <span className="underline">CAMPAIGN</span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <span className="nav-link" style={{ cursor: "pointer" }}>
                        <span className="underline">CATALOG</span>
                      </span>
                    </li>
                    <li className="nav-item">
                      <span className="nav-link" style={{ cursor: "pointer" }}>
                        <span className="underline">CONTACT</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
