import { useState } from "react";
import { notify } from "../../Utils/ReusableFunctions";
import { createProperty } from "../../DataService/PropertiesDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { useShowInfo } from "../../Context/ShowInfo";
import { MDBInput } from "mdb-react-ui-kit";

export const CreateProperty = ({ setProperties }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [property, setProperty] = useState({
    Code: "",
    Name: "",
    SortOrder: 1,
  });
  const { setCustomMessage } = useShowInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProperty((prevPriceGroup) => ({
      ...prevPriceGroup,
      [name]: value,
    }));
  };

  const handleCreateProperty = () => {
    createProperty(
      ApiUrls.properties,
      sessionToken,
      property,
      notify,
      setCustomMessage,
      setProperty,
      setProperties
    );
  };

  return (
    <div
      className="modal fade"
      id="propertiesModal"
      aria-labelledby="propertiesModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="propertiesModalLabel">
              Create property
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-2">
              <MDBInput
                id="propertyCode"
                type="text"
                className="form-control"
                label="Code"
                name="Code"
                autoComplete="off"
                value={property.Code}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group mb-1">
              <MDBInput
                id="propertyName"
                type="text"
                className="form-control"
                label="Name"
                name="Name"
                autoComplete="off"
                value={property.Name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button "
              className="btn btn-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary  btn-sm"
              onClick={handleCreateProperty}
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
