import { SortProducts } from "../FilterAndSort/SortProducts";
import { FilterProducts } from "../FilterAndSort/FilterProducts";
import { useState } from "react";


export const OffCanvas = () => {
  const [selectedSort, setSelectedSort] = useState(""); 
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header border-bottom">
        <div className="offcanvas-title fw-bolder h5" id="offcanvasRightLabel">
          SORT AND FILTER
        </div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <SortProducts selectedSort={selectedSort} setSelectedSort={setSelectedSort} />
        <div>
          <FilterProducts  setSelectedSort={setSelectedSort} />
        </div>
      </div>
    </div>
  );
};
