import { useState } from "react";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { fetchData } from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";

export const AddPropertyValueModal = ({
  selectedProperty,
  setPropertiesValue,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [propertyValue, setPropertyValue] = useState({
    Code: "",
    Name: "",
    PropertyID: selectedProperty.ID,
    NameTranslations: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPropertyValue({ ...propertyValue, [name]: value });
  };

  const addNewProperty = async () => {
    console.log(propertyValue);
    try {
      const response = await fetch(ApiUrls.propertyValues, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(propertyValue),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        fetchData(ApiUrls.propertyValues, sessionToken).then((data) => {
          setPropertiesValue(data.value);
        });
      } else {
        throw new Error("Failed to create property value");
      }
    } catch (error) {
      console.error("Error adding new property value:", error.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="staticBackdropLabel">
              Add New Property Value
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-2" style={{ textAlign: "left" }}>
              <MDBInput
                id="propertyValueCode"
                type="text"
                className="form-control"
                label="Code"
                name="Code"
                autoComplete="off"
                value={propertyValue.Code}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-2" style={{ textAlign: "left" }}>
              <MDBInput
                id="namePropertyValue"
                type="text"
                className="form-control"
                label="Name"
                name="Name"
                autoComplete="off"
                value={propertyValue.Name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={addNewProperty}
              data-bs-dismiss="modal"
            >
              Add New Property Value
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
