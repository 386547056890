import { useEffect, useState } from "react";
import logo from "../../Images/asmodee.svg";
import { useCart } from "../../Context/CartContext";
import { useProductList } from "../../Context/ProductListContext";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { useNavigate } from "react-router-dom";
import "./Checkout.css";
import { fetchData } from "../../Utils/ReusableFunctions";

export const Checkout = ({ formatCurrency }) => {
  const navigate = useNavigate();
  const { cartItems, setCartItems } = useCart();
  const { prices } = useProductList();
  const [totalPrice, setTotalPrice] = useState(0);
  const [agree, setAgree] = useState(false);
  const sessionToken = localStorage.getItem("bearerToken");
  const [order, setOrder] = useState({
    OrderInfo: {
      reference: "",
      comments: "",
    },
  });
  const storedDecodedString = localStorage.getItem("decoded");
  const storedDecoded = storedDecodedString
    ? JSON.parse(storedDecodedString)
    : null;

  useEffect(() => {
    fetchData(
      "https://shop.decentra.net/odata/cart?$expand=product",
      sessionToken
    )
      .then((data) => {
        setCartItems(data.value);
      })
      .catch((error) => {
        console.error("Error fetching cart items:", error);
      });
  }, [sessionToken, setCartItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let totalPrice = 0;
      cartItems.forEach((item) => {
        const price = prices.find((p) => p.productId === item.ProductID);
        if (price) {
          totalPrice += price.price * item.Qty;
        }
      });
      setTotalPrice(totalPrice);
    }
  }, [cartItems, prices]);

  const handleAgreeChange = (e) => {
    setAgree(e.target.checked);
  };
  const handleNavigate = (id) => {
    navigate("/shop/orderconfirmation", { state: { orderId: id } });
  };

  const completeOrder = async () => {
    console.log(order);
    try {
      const response = await fetch(ApiUrls.orderComplete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(order),
      });
      if (response.ok) {
        console.log("Order Complete");
        setOrder({
          OrderInfo: {
            reference: "",
            comments: "",
          },
        });
        const responseData = await response.json();
        console.log(responseData);
        handleNavigate(responseData);
      } else {
        throw Error("Order not complete");
      }
    } catch (error) {
      console.log("Error making order");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder((prevOrder) => ({
      ...prevOrder,
      OrderInfo: {
        ...prevOrder.OrderInfo,
        [name]: value,
      },
    }));
  };

  return (
    <div className="">
      <nav className="navbar border-bottom d-flex align-items-center justify-content-center shadow-none">
        <div className="navbar-brand">
          <img src={logo} width={200} alt="logo" className="mb-2 mt-2" />
        </div>
      </nav>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 text-left">
            <div className=" align-items-center justify-content-center mt-4">
              EMAIL:{" "}
              <span className="fw-bolder">{storedDecoded.unique_name}</span>
            </div>
            <hr />
            <div className="">
              CUSTOMER ID:{" "}
              <span className="fw-bolder">{storedDecoded.deauth_customer}</span>
            </div>
            <hr />
            <div className="mt-4">
              <h4>Reference</h4>
              <input
                className="form-control"
                onChange={handleInputChange}
                value={order.OrderInfo.reference}
                name="reference"
              />
            </div>

            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                checked={agree}
                onChange={handleAgreeChange}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I AGREE ON EVERYTHING
              </label>
            </div>
            <div id="columnOneButton">
              <button
                className="btn mt-4 w-100 text-white fw-bolder shadow-none"
                style={{ background: "#0227B7" }}
                disabled={!agree}
                onClick={completeOrder}
              >
                {" "}
                COMPLETE ORDER{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="ms-2">
              <h4 className="mt-4">Order Overview</h4>
              <div className="">
                <div>
                  {cartItems && cartItems.length > 0 && (
                    <ol className="list-group list-group-flush mt-3">
                      {cartItems.map((item) => {
                        const price = prices.find(
                          (price) => price.productId === item.ProductID
                        );
                        return (
                          <li
                            key={item.ID}
                            className="list-group-item d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">
                                {item.Product && item.Product.Name}
                              </div>
                              {item.Product && item.Product.Code}
                              <div>
                                {price?.price && formatCurrency(price.price) }
                              </div>
                            </div>

                            <div className="d-flex flex-column align-items-end">
                              <div></div>
                              <div className="d-flex justify-content-between align-items-end">
                                <span
                                  className="badge rounded-pill"
                                  style={{ background: "#D5D5D5" }}
                                >
                                  {item.Qty}
                                </span>
                              </div>
                              <div>
                                {price?.price &&
                                  formatCurrency(price.price * item.Qty)}{" "}
                                kr.
                              </div>
                            </div>
                            <div></div>
                          </li>
                        );
                      })}
                    </ol>
                  )}
                </div>
              </div>
            </div>
            <ol className="list-group list-group-flush mt-3">
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <h4>SUB TOTAL</h4>
                  </div>
                </div>
                <div className="">
                  <span className="fw-bolder">
                    <h4>
                      {" "}
                      <span
                        className=""
                        style={{
                          color: "#8E8E8E",
                          fontSize: "1rem",
                          fontWeight: "lighter",
                        }}
                      >
                        DKK
                      </span>{" "}
                      {formatCurrency(totalPrice)} kr.{" "}
                    </h4>
                  </span>
                </div>
              </li>
            </ol>
            <div id="columnTwoButton" className="mb-4">
              <button
                className="btn mt-4 w-100 text-white fw-bolder shadow-none"
                style={{ background: "#0227B7" }}
                disabled={!agree}
                onClick={completeOrder}
              >
                {" "}
                COMPLETE ORDER{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
