import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
  getProperties,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import { updateCustomer } from "../../DataService/CustomerDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { CreateCustomer } from "./CreateCustomer";

export const Customers = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setCustomMessage, languageOptions } = useShowInfo();
  const [properties, setProperties] = useState([]);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.customerPropertiesApi,
      sessionToken,
      languageOptions,
      setProperties
    );
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.customerApi, sessionToken).then((data) => {
      setCustomerList(data.value);
      console.log(data.value);
      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

  const filteredList = filterListByName(customerList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedCustomer,
        setSelectedCustomer
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedCustomer]);

  const handleSave = async () => {
    try {
      const updatedCustomerList = await updateCustomer(
        ApiUrls.customerApi,
        sessionToken,
        selectedCustomer,
        fetchData
      );
      console.log("Customer updated successfully");
      setCustomMessage("Customer updated successfully");
      setCustomerList(updatedCustomerList);
    } catch (error) {
      notify("Error updating customer");
      console.error("Error updating customer:", error);
    }
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5 className="d-flex align-items-center">
              Customer{" "}
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#customerModal"
              >
                 <i  className="bi bi-plus-circle-dotted" style={{fontSize:"1rem"}}></i>
              </button>
            </h5>
            <div>
              <input
                id="customerSearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedCustomer?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedCustomer(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedCustomer && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Customer Details</h5>
              </div>
              <MDBInput
                name="customerName"
                id="customerName"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedCustomer.Name}
                onChange={(e) => {
                  setSelectedCustomer((prevCustomer) => ({
                    ...prevCustomer,
                    Name: e.target.value,
                  }));
                }}
                label={properties[4].Label}
                type="Name"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[4].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn-sm btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateCustomer setCustomerList={setCustomerList} />
    </div>
  );
};
