import { fetchData } from "../Utils/ReusableFunctions";
import { notify } from "../Utils/ReusableFunctions";
import { ApiUrls } from "./ApiUrlsDataService";
export const updateUser = async (
  apiUrl,
  sessionToken,
  selectedUser,
  fetchData
) => {
  if (!selectedUser) {
    throw new Error("No user selected");
  }
  const patchUrl = `${apiUrl}(${selectedUser.ID})`;
  const patchData = {
    Name: selectedUser.Name,
    Username: selectedUser.Username,
    Active: selectedUser.Active,
  };
  const response = await fetch(patchUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify(patchData),
  });
  if (!response.ok) {
    throw new Error(`Error updating user. Status: ${response.status}`);
  }
  const data = await fetchData(apiUrl, sessionToken);
  return data.value;
};

export const createUser = async (
  apiUrl,
  sessionToken,
  user,
  notify,
  setCustomMessage,
  setUser,
  setSelectedValue,
  setUserList,
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(user),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("User created:", data);
      setCustomMessage("User created");
      setUser({
        Username: "",
        Active: true,
        TenantID: "",
        Name: "",
      });
      setSelectedValue(null);
      fetchData(ApiUrls.userApi, sessionToken).then((data) => {
        setUserList(data.value);
      });
   
    } else {
      notify("Error creating user");
      throw Error("Failed to create user");
    }
  } catch (error) {
    notify("Error creating user");
    console.error("Error creating user:", error);
  }
};


export const assignUserToRole = async (
  apiUrl,
  sessionToken,
  selectedUserId,
  selectedRoleId,
  setCustomMessage
) => {
  try {
    if (selectedUserId && selectedRoleId) {
      const assignRoleApi = `${apiUrl}`;
      const assignData = {
        userId: selectedUserId,
        roleId: selectedRoleId,
      };
      const response = await fetch(assignRoleApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      });
      if (response.ok) {
        setCustomMessage("Role assigned");
        const updatedUserList = await fetchData(ApiUrls.userApi, sessionToken);
        return updatedUserList.value;
      } else {
        console.error("Error assigning role to user. Status:", response.status);
        notify("Error assigning role to user");
      }
    }
  } catch (error) {
    console.error("Error assigning role to user:", error);
    notify("Error assigning role to user");
  }
};
