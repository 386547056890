import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useShowInfo } from "../../Context/ShowInfo";
import { assignRoleToUser } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";

export const AssignRoleModal = ({ selectedRole }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { setCustomMessage } = useShowInfo();

  useEffect(() => {
    fetchData(ApiUrls.userApi, sessionToken).then((data) => {
      setUserList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  const handleAssignRole = () => {
    assignRoleToUser(
      ApiUrls.userRoleApi,
      sessionToken,
      selectedUserId,
      selectedRole?.ID,
      setCustomMessage
    );
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="staticBackdropLabel">
              Assign role to user
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-2" style={{ textAlign: "left" }}>
              {selectedRole.Name}
            </div>
            <select
            id="selectRole"
              className="form-select"
              aria-label="Default select example"
              onChange={handleUserChange}
            >
              <option>Choose user</option>
              {userList.map((user) => (
                <option key={user.ID} value={user.ID}>
                  {user.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="closeButton"
              type="button"
              className="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              onClick={handleAssignRole}
            >
              Assign Role
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
