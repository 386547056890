import { useShowInfo } from "../../Context/ShowInfo";

export const InfoBarBottom = () => {
  const { customMessage } = useShowInfo();

  return (
    <div>
      <nav className="navbar fixed-bottom navbar-light bg-light">
        <div className="container-fluid">
          <small
            style={{ height: "1rem", fontSize: "0.7rem", marginLeft: "auto" }}
            className="navbar-brand"
          >
            <div>{customMessage}</div>
          </small>
        </div>
      </nav>
    </div>
  );
};
