import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const sessionToken = localStorage.getItem("bearerToken");

  if (!sessionToken) return <Navigate to="/" />;
  return <Outlet />;
};

export default PrivateRoute;
