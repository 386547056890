import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { AssignRoleModal } from "./AssignRoleModal";
import { notify, getProperties } from "../../Utils/ReusableFunctions";
import { updateRole } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { useShowInfo } from "../../Context/ShowInfo";
import { CreateRole } from "./CreateRoles";

export const Roles = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setCustomMessage, languageOptions } = useShowInfo();
  const [properties, setProperties] = useState([]);

  const handleGetProperties = useCallback(() => {
    getProperties(
      ApiUrls.rolePropertiesApi,
      sessionToken,
      languageOptions,
      setProperties
    );
  }, [sessionToken, languageOptions]);

  const notifyError = (errorMessage) => {
    notify(errorMessage);
  };

  useEffect(() => {
    fetchData(ApiUrls.roleApi, sessionToken).then((data) => {
      setRoleList(data.value);
      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

  const filteredList = filterListByName(roleList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedRole, setSelectedRole);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedRole]);

  const handleSave = async () => {
    try {
      const updatedRoleList = await updateRole(
        ApiUrls.roleApi,
        sessionToken,
        selectedRole,
        fetchData
      );
      console.log("Role updated successfully");
      setCustomMessage("Role updated successfully");
      setRoleList(updatedRoleList);
    } catch (error) {
      notify("Error updating role");
      console.error("Error updating role:", error);
    }
  };

  const handleChange = (field, value) => {
    setSelectedRole((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage("");
    }
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5 className="d-flex align-items-center">
              Roles
              <button
                className="btn btn-sm btn-secondary ms-2"
                data-bs-toggle="modal"
                data-bs-target="#roleModal"
              >
                <i
                  className="bi bi-plus-circle-dotted"
                  style={{ fontSize: "1rem" }}
                ></i>
              </button>
            </h5>
            <div>
              <input
                id="roleSearch"
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedRole?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedRole(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small key={item.ID}>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedRole && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Role Details</h5>
              </div>
              <MDBInput
                name="Code"
                id="Code"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedRole.Code}
                onChange={(e) => handleChange("Code", e.target.value)}
                label={properties[0].Name}
                type="text"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[0].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBInput
                name="roleName"
                id="roleName"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedRole.Name}
                onChange={(e) => handleChange("Name", e.target.value)}
                label={properties[1].Name}
                type="text"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[1].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <MDBInput
                name="Description"
                id="Description"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedRole.Description}
                onChange={(e) => handleChange("Description", e.target.value)}
                label={properties[2].Name}
                type="text"
                size="md"
                onFocus={() =>
                  handleInputSelect(true, properties[2].Description)
                }
                onBlur={() => handleInputSelect(false)}
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-1 w-100 btn btn-sm btn-success"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mb-1 w-100 btn btn-sm btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Assign role to user
                </button>
                <AssignRoleModal
                  selectedRole={selectedRole}
                  notifyError={notifyError}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateRole setRoleList={setRoleList} />
    </div>
  );
};
