import { useEffect, useState } from "react";
import logo from "../../Images/asmodee.svg";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";

export const OrderConfirmation = () => {
  const [orderConfirmation, setOrderConfirmation] = useState([]);
  const sessionToken = localStorage.getItem("bearerToken");
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.state?.orderId;
  const { fetchCartItems } = useCart();

  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  useEffect(() => {
    if (orderId) {
      fetchData(`${ApiUrls.getOrder}/${orderId.value}`, sessionToken).then(
        (data) => {
          setOrderConfirmation(data);
        }
      );
    }
  }, [sessionToken, orderId]);

  return (
    <div>
      <nav className="navbar border-bottom d-flex align-items-center justify-content-center shadow-none">
        <div className="navbar-brand">
          <img
            src={logo}
            width={200}
            alt="logo"
            className="mb-2 mt-2 img-fluid"
          />
        </div>
      </nav>
      <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center shadow-none">
          <h4 className="fw-bolder mt-4">ORDER CONFIRMATION</h4>
          {orderConfirmation ? (
            <ul className="list-group mt-4 rounded-0 w-50 w-lg-50">
              <li className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">UserID:</span>
                <span>{orderConfirmation.UserID}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">CustomerID:</span>
                <span>{orderConfirmation.CustomerID}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">Reference:</span>
                <span>{orderConfirmation.Reference}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">Comments:</span>
                <span>{orderConfirmation.Comments}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">Status:</span>
                <span>{orderConfirmation.Status}</span>
              </li>
            </ul>
          ) : (
            <p>Loading...</p>
          )}
          <button
            className="btn mt-4 btn-secondary w-50 w-lg-50"
            onClick={() => navigate("/shop")}
          >
            GO BACK TO SHOP
          </button>
        </div>
      </div>
    </div>
  );
};
